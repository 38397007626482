<template>
  <li class="list-main__item">
    <button @click="onClickItem(data)" class="list-main__item-link" :class="buttonClass">
      <span class="list-main__item-value">
        <i class="el-icon-menu m-r-1" style="margin-top: 0.1rem"></i>
        {{ data.Lanqkey01 }}
      </span>
      <span class="list-main__item-defaultvalue m-l-2--xs">
        <span class="badge" v-if="data.MIQDefaultIsDefault" :style="{ 'background-color': data.MIQDefaultIsDefault }"></span>
        {{ data.DefaultValue }}
      </span>
    </button>

    <!-- Actions -->
    <div v-if="data.PdfOnPath || data.InfoTooltipNumber">
      <ListItemActions :data="data"></ListItemActions>
    </div>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </li>
</template>

<script>
import DebugItem from '@/components/DebugItem';
import ListItemActions from '@/components/List/ListItemActions';
import mixins from '@/shared/mixins/index';

export default {
  name: 'ANZTILECHOOSER',

  mixins: [mixins],

  components: {
    DebugItem,
    ListItemActions
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    buttonClass() {
      return {
        'is-disabled': this.data.NumberOfNodeChilds === 1 || this.data.IsLocked === 'Y'
      };
    }
  },

  data() {
    return {};
  },

  methods: {
    onClickItem(node) {
      this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: node.Xnodekey });

      this.requestNode(node.Xnodekey).then(response => {
        this.$store.commit('pages/HIDE_LOADING');
        this.$store.commit('webpoptilechooser/OPEN', {
          node: response.data.RESPONSE,
          visible: true
        });
      });
    }
  }
};
</script>
